import React from 'react'
import { navigate } from 'gatsby'
import Layout from '../components/layout'
import PrivateBetaBanner from '../components/private-beta-banner'
import ReserveYourUsernameButton from '../components/reserve-your-username-button'
import SEO from '../components/seo'
import styles from './index.module.scss'
import { Formik, Field } from 'formik';
import ShareYourStyle1Img from '../components/index/share-your-style-1-image'
import ShareYourStyle2Img from '../components/index/share-your-style-2-image'
import EndlessInspirationImg from '../components/index/endless-inspiration-image'
import DiscoverOutfitImg from '../components/index/search-image'
import FavoriteBrandImg from '../components/index/favorite-brand-image'
import UploadAndEarnImg from '../components/index/upload-and-earn-image'
import arrowIcon from '../images/arrow.svg'

const ReserveYourUsernameForm = () => {
  return (
    <Formik
      initialValues={{
        reservedUsername: '',
      }}
      onSubmit={values => {
        navigate(`/private-beta/?reserved-username=${values.reservedUsername}`);
      }}
    >
      {
        props => {
          const { handleSubmit } = props;
          return (
            <form onSubmit={handleSubmit} className={`relative mt-5 ${styles.form}`}>
              <Field
                className={`w-full px-3 py-2 rounded-md absolute ${styles.field}`}
                type='text'
                name='reservedUsername'
                placeholder='Reserve your username'
              />
              <img
                src={arrowIcon}
                className={styles.arrow}
                onClick={handleSubmit}
                aria-hidden='true'
                alt='Submit'
              />
            </form>
          )
        }
      }
    </Formik>
  )
}

const IndexPage = () => (
  <Layout>
    <SEO title='Home' />

    <div className='container mx-auto px-4 lg:px-8 mb-12'>
      <div className='pt-12 lg:pt-0 flex flex-row justify-center lg:justify-between items-center'>
        <div className={styles.textSection}>
          <h1 className={styles.heading}>
            Share your style,
            <br />
            Shop from inspiration
          </h1>
          <p className='my-5'>Discover styles that inspire you, shop the look and get rewarded for your influence.</p>
          <div className='block sm:hidden'>
            <ReserveYourUsernameButton />
          </div>

          <div className='hidden sm:block'>
            <ReserveYourUsernameForm />
          </div>
        </div>
        <div className={`lg:hidden ${styles.shareYourStyle1Img}`}>
          <ShareYourStyle1Img />
        </div>
        <div className={`hidden lg:inline-block ${styles.shareYourStyle2Img}`}>
          <ShareYourStyle2Img />
        </div>
      </div>

      <div className='mt-32 md:mt-16 block flex flex-col sm:flex-row-reverse justify-evenly items-center'>
        <div>
          <h2>Endless inspiration, tailored to you</h2>
          <p className='mt-6 mb-8 md:my-2'>Find outfits for any occasion with your personalised feed</p>
        </div>
        <div className={`${styles.img} px-8 md:px-3`}>
          <EndlessInspirationImg />
        </div>
      </div>

      <div className='mt-32 md:mt-16 block flex flex-col sm:flex-row justify-evenly items-center'>
        <div>
          <h2>Discover outfit combinations</h2>
          <p className='mt-6 mb-8 md:my-2'>Combine any item with our powerful search</p>
        </div>
        <div className={`${styles.img} px-8 md:px-3`}>
          <DiscoverOutfitImg />
        </div>
      </div>

      <div className='mt-32 md:mt-16 block flex flex-col sm:flex-row-reverse justify-evenly items-center'>
        <div>
          <h2>Favourite brands at your fingertips</h2>
          <p className='mt-6 mb-8 md:my-2'>Shop hundreds of stores with one simple checkout</p>
        </div>
        <div className={`${styles.img} px-8 md:px-3`}>
          <FavoriteBrandImg />
        </div>
      </div>

      <div className='mt-32 md:mt-16 mb-32 block flex flex-col sm:flex-row justify-evenly items-center'>
        <div>
          <h2>Upload and earn</h2>
          <p className='mt-6 mb-8 md:my-2'>Earn money from your uploads when you inspire others</p>
        </div>
        <div className={`${styles.img} px-8 md:px-3`}>
          <UploadAndEarnImg />
        </div>
      </div>
    </div>

    <PrivateBetaBanner />
  </Layout>
)

export default IndexPage
